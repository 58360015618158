import { Link } from 'react-router-dom';
import logogif from './lobshr.gif';
import { useAuthValue } from "./AuthContext"
import firebase from './Firestore'
import {signInWithEmailAndPassword, signOut} from 'firebase/auth'


export function JobsHeader() {
    return (
      <>
            <div className="text-center">
            <Link to="/">
                <img src={logogif} className="w-32 inline text-center" />
                <span className="inline-block align-middle text-center text-6xl font-serif font-light italic text-neon text-white">LOBSJOBS</span></Link>
            </div>
            <div className="grid grid-cols-6 gap-4 max-w-5xl mx-auto">
              <Link to="/jobs" className="nav-neon">
                jobs</Link>
              <Link to="/postjob" className="nav-neon">
                post job</Link>

            </div>
                  </>
    );
  }

  export function JobsFooter() {
    const {currentUser} = useAuthValue()
    const auth = firebase.auth
    return (
      <>
<div className="grid grid-cols-6 gap-4 max-w-5xl mx-auto">
              {currentUser ? <><button className="bg-black nav-neon text-sm" onClick={() => signOut(auth)}>logout</button>
              <Link className="bg-black nav-neon text-sm" to="/approvals">approvals</Link></>
            : <Link className="bg-black nav-neon text-sm" to="/login">admin login</Link> }
            </div>
                  </>
    );
  }