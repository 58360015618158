import firebase from './Firestore'
import {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Linkify from 'react-linkify'
import {JobsHeader, JobsFooter} from './JobsComponents'


dayjs.extend(relativeTime)

function Jobs() {
    const [jobs, setJobs] = useState([])


    useEffect( () => {
      getJobs()
  }, [])
  
  const getJobs = () => {
      setJobs([])
    firebase.db.collection('jobs')
    .orderBy('date', 'desc')
    .where("approved", "==", true)
    .get()
      .then(querySnapshot => {
          console.log(querySnapshot);
      querySnapshot.forEach( doc => {
        setJobs(prev => ([...prev, doc.data()]))
      })
    })
    .catch(err => {
      console.log(err.message)
    })
  }
  
    return (
  <>
  <JobsHeader />
  <div className="grid grid-cols-6 gap-4">

    {

    jobs.map((job, i) => {
      return <ApprovedJob key={i} jobdata={job} />
    })}
  </div>
  <JobsFooter />

    </>
    );
    
  }
  
  function ApprovedJob({jobdata}) {
    const hidden = false
    return (
  <>
  <Linkify>
  <div className="content-box">
  <div className="text-md font-bold">{jobdata.project}</div>
  {!hidden && <><div className="whitespace-pre-line text-sm">{jobdata.website}</div>
  <div className="whitespace-pre-line text-sm">Description: {jobdata.blurb} </div>
  <div className="whitespace-pre-line text-sm">Looking For: {jobdata.role} </div>
  <div className="whitespace-pre-line text-sm ">Salary: {jobdata.salary}</div>
  <div className="text-sm">contact: {jobdata.contact}</div>
  <div className="text-xs">{dayjs(jobdata.date).fromNow()}</div> </>}
        </div>
        </Linkify>

    </>
    );
  }

  
  export default Jobs