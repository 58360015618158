import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import firebase from './Firestore'
import {createUserWithEmailAndPassword, signOut} from 'firebase/auth'
import { useAuthValue } from "./AuthContext"
import {JobsHeader, JobsFooter} from './JobsComponents'



// const app = firebase.app
const auth = firebase.auth
const formSuccess = () => toast.success('User registered');
const formFailure = (err) => toast.error('Something broke, told u amplice was a shit coder. Error: ' + err)

function Register() {
  const { register, handleSubmit, watch, formState } = useForm();
  const {currentUser} = useAuthValue()

  const onRegister = async (data, e) => {
    console.log(data.email);    
    console.log(data.password);
    createUserWithEmailAndPassword(auth, data.email, data.password)
    .then((res) => {
        console.log(res.user)
        formSuccess()
        e.target.reset()
      })
    .catch(err => {
      console.log(err.message)
      formFailure(err)
    })
  }

  return (
    <>
      <JobsHeader />
      <div className="col-span-6 md:col-span-6 text-left p-6 m-4 rounded-md space-y-4 border-2 border-red-400 shadow-red-500 shadow-neon">
      <h3>Register</h3>
      {currentUser ? <div> you are logged in as {currentUser.email} <button className="bg-black nav-neon text-sm" onClick={() => signOut(auth)}>Sign Out</button></div>
        :<form onSubmit={handleSubmit(onRegister)} className="bg-gray-900 rounded-md px-8 pt-6 pb-8">
          Email: <input type="email" placeholder="Email Address" {...register("email", { required: true })} />
          {formState.errors.email && <p className="text-red-500">This field is required</p>}
          Password: <input type="password" placeholder="Password"{...register("password", { required: true, minLength: 8})} />
          {formState.errors.password && <p className="text-red-500">This field is required</p>}
          <input className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded" type="submit" />

        </form>}
      </div>
      <Toaster />
      <JobsFooter />


    </>
  )
}

export default Register