import firebase from './Firestore'
import {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import Linkify from 'react-linkify'
import toast, { Toaster } from 'react-hot-toast';
import { Bot } from "tgapi"
import { useAuthValue } from "./AuthContext"
import { Link } from 'react-router-dom';
import {JobsHeader, JobsFooter} from './JobsComponents'



function Approvals() {
    const [allJobs, setAllJobs] = useState([])
    const {currentUser} = useAuthValue()

    useEffect( () => {
      getJobs()
  }, [])
  
  const getJobs = () => {
    setAllJobs([])
    firebase.db.collection('jobs')
    .orderBy('approved','asc', 'date', 'desc')
    .get()
      .then(querySnapshot => {
          console.log(querySnapshot);
      querySnapshot.forEach( doc => {
        setAllJobs(prev => (
          [...prev, {id: doc.id, ...doc.data()}]
          )
        )
      })
    })
    .catch(err => {
      console.log(err.message)
    })
  }
  
    return (
  <>
  <JobsHeader />
  {currentUser && currentUser.email ? 
  <div className="grid grid-cols-6 gap-4">
    {allJobs.map((job, i) => {
      return <Job key={i} getJobs={getJobs} jobdata={job}  />
    })}
  </div>
  :
  <div className="content-box">you are not logged in, you can't approve shit <br />
  <Link to="/login" className="">login here</Link></div>
  }
        <JobsFooter />

    </>
    );
    
  }
//-1001382890433
//1382890433

  function Job({getJobs, jobdata}) {
    // const test_chat_id = "-785505868"
    //https://t.me/+ieT9c2J1OC0wNGY8
    const chat_id = "@lobsters_hr"
    const bot = new Bot('5302097425:AAFMMJjJK-eT--TdUMlWX157om0U4LLhe74')
    const [approvalState, setApprovalState] = useState(jobdata.approved)

    const changeApproval = (approvedOrNot, id) => {
      console.log(id)
        firebase.db.collection("jobs").doc(id).update({
            approved: !approvedOrNot
        })
        .then(async () => {
            toast.success('Job ' + (approvedOrNot ? 'unapproved': 'approved'))
            console.log("Document successfully updated!");
            setApprovalState(!approvalState)
            const jobDoc = await firebase.db.collection("jobs").doc(id).get()
            const jobdata = jobDoc.data();
            jobdata.approved === true && sendData(jobdata)
          })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            toast.error("Job status not updated. Probably you don't have permissions? Error: " + error)
        })
    }

    const deleteJob = (id) => {
      console.log(id)
      const reallyDelete = window.confirm("are you sure you want to delete this job?")
      if (reallyDelete) {
        firebase.db.collection("jobs").doc(id).delete()
        .then(async () => {
            toast.success('Job deleted')
            console.log("Document successfully deleted!");
            getJobs()
          })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            toast.error("Job status not updated. Probably you don't have permissions? Error: " + error)
        })
      }
    }

    async function sendData(data) {
      bot.sendMessage({chat_id,
        text: '❗️HIRING \n\n <b>' + data.project + '</b> \n\n' +
        data.website + '\n\n' + 
        'Description: ' + data.blurb + '\n\n' + 
        'Looking For: ' + data.role + '\n\n' + 
        'Salary: ' + data.salary + '\n\n' + 
        'Contact: ' + data.contact + '\n\n',
        parse_mode: 'HTML'
      })
      .then(console.log)
      // let optionalParams = {
      //   parse_mode: "MarkdownV2",
      //   disable_web_page_preview: true,
      // }
      // const response = await slimbot.sendMessage('-785505868', '~hello~', optionalParams);
      // console.log(response);
    }

    return (
  <>
  
  <Linkify>
  <div className="content-box">
  <div className="text-md font-bold">{jobdata.project}</div>
  <div className="text-md font-bold text-red-500">
    Currently Approved: {approvalState.toString()} 
  <button className="bg-black nav-neon text-sm" value={jobdata.id} onClick={() => changeApproval(approvalState, jobdata.id)}>
  {approvalState ? "unapprove" : "approve"}</button></div>
<button className="bg-black nav-neon text-sm mx-0" onClick={() => deleteJob(jobdata.id)}>delete</button>
  <div className="whitespace-pre-line text-sm">{jobdata.website}</div>
  <div className="whitespace-pre-line text-sm">Description: {jobdata.blurb} </div>
  <div className="whitespace-pre-line text-sm">Looking For: {jobdata.role} </div>
  <div className="whitespace-pre-line text-sm ">Salary: {jobdata.salary}</div>
  <div className="text-sm">contact: {jobdata.contact}</div> 
  <div className="text-xs">{dayjs(jobdata.date).fromNow()}</div>
        </div>
        </Linkify>
        <Toaster />

    </>
    );
  }

  
  export default Approvals