import logo from './logo2.png';
import './App.css';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import GrantData from './grants.json'
import Jobs from './Jobs'
import JobForm from './JobForm'
import Approvals from './Approval'
import Login from './Login'
import Register from './Register'
import { AuthProvider } from './AuthContext'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { useEffect, useState } from 'react'
import firebase from './Firestore'




function App() {
  const auth = firebase.auth
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])
  return (
    <>
      <BrowserRouter>
        <AuthProvider value={{ currentUser }}>
          <div className="px-8 py-4 max-w-7xl mx-auto">
            <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/postjob" element={<JobForm />} />
              <Route path="/approvals" element={<Approvals />} />
              {/* <Route path="/grants" element={<Grants />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Routes>
          </div>
        </AuthProvider>
      </BrowserRouter>

    </>
  );
}

function Home() {
  return (
    <>
                <div className="text-center font-pixel">

              <Link to="/">
                <img src={logo} className="w-72 inline text-center" />
                </Link>
                <div>
                <h2>hello seafood</h2>
                <h3>important links for aquatic creatures</h3>
                <div className="m-8"><a href="https://t.me/lobsters_chat"><span className="eightbit-outer"><span className="eightbit-inner">og defi chat</span></span></a>
                <div className="m-10"><a href="https://t.me/NFT_avenue"><span className="eightbit-outer"><span className="eightbit-inner">nft avenue chat</span></span></a></div>
                <div className="m-10"><a href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAxNTc3NzExMjQx"><span className="eightbit-outer"><span className="eightbit-inner">lobsterdao NFT Token-gated Community Chat</span></span></a></div>
                <div className="m-10"><Link to="/jobs"><span className="eightbit-outer"><span className="eightbit-inner">lobsjobs - lobsterdao job board</span></span></Link></div>
                <div className="m-10"><a href="https://t.me/lobsters_daily"><span className="eightbit-outer"><span className="eightbit-inner">defi chat daily summary</span></span></a></div>
                <div className="m-10"><a href="https://twitter.com/10b57e6da0"><span className="eightbit-outer"><span className="eightbit-inner">lobsterdao Twitter</span></span></a></div>
                <div className="m-10"><a href="https://t.me/blockchain_lobsters"><span className="eightbit-outer"><span className="eightbit-inner">ivan's rants and shills</span></span></a></div>
                <div className="m-10"><a href="https://looksrare.org/collections/0x026224A2940bFE258D0dbE947919B62fE321F042"><span className="eightbit-outer"><span className="eightbit-inner">lobsterdao collection on looksrare</span></span></a></div>
                <div className="m-10"><a href="https://opensea.io/collection/lobsterdao"><span className="eightbit-outer"><span className="eightbit-inner">lobsterdao collection on opensea</span></span></a></div>
</div>




        <h3> what are lobsters?</h3>
        <br /> LobsterDAO is an OG collective of DeFi & NFT degens and whales. The community started in TG and dates back to summer 2018, comprisingome of the most notable founders and researchers in the crypto scene. We were among the first to LP in Curve as well as other large protocols!
        <br />As such, some say, lobsterdao NFT is a badge of honour and IQ. But probably only stupid people would say that.  

        <br /> The quality of discussion is kept very high and the moderation is strict. Don't post bullshit or you will get muted/banned.


<h3 className="mt-4"> what is lobstersdao and what are these NFTs?</h3>                
<br /> The OG lobsterchat made a commemorative NFT that was distributed to participants in the chat based on historical activity. Some of the people who received the commemorative NFT thought that it made sense to turn this into an informal "DAO" since the chat participants have such a deep knowledge of defi and blockchain stuff. So we took the idea of 1 NFT = 1 vote, started using snapshot, and voila! a DAO is born.

<br />The collection contains 6751 pieces done by Klibansky, who is a real artist in galleries and museums and stuff! There is a DAO with a multisig and a working group that everyone can contribute to. See all links for info.


</div>
                </div>
    </>
  );
}

function Grants() {
  return (
    <>
      <div className="grid grid-cols-6 gap-4">
        {GrantData.map((grant) => {
          return <Grant grantdata={grant} />
        })}
      </div>
    </>
  );
}

function Grant({ grantdata }) {
  console.log(grantdata);
  return (
    <>
      <div className="content-box">
        <div className="text-lg font-bold">{grantdata.topic}</div>
        <div className="text-md font-bold">{grantdata.project}</div>
        <div className="text-md font-semibold">Grant Information</div>
        <div className="whitespace-pre-line text-xs">{grantdata.blurb} </div>
        <div className="text-xs">Prize: {grantdata.amount}</div>
        <div className="text-xs">1 day</div>
      </div>
    </>
  );
}

export default App;
