import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import firebase from './Firestore'
import { useState, useEffect } from 'react'
import { signInAnonymously, signOut } from 'firebase/auth'
import { useAuthValue } from "./AuthContext"
import {JobsHeader, JobsFooter} from './JobsComponents'


// import { Bot } from "tgapi"
// import useWeb3Modal from "./hooks/newWeb3Modal";
// import lobstersNftAbi from "./abi/lobstersNftAbi.json";
// import { ethers } from "ethers";

// function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {

//   return (
//     <>
//       <button className="bg-black border-2 border-red-500 rounded-lg text-white p-6 text-sm mb-4 shadow-red-500 shadow-neon text-neon hover:text-neon-white hover:bg-red-500"
//         onClick={() => {
//           if (!provider) {
//             loadWeb3Modal();
//           } else {
//             logoutOfWeb3Modal();
//           }
//         }}
//       >
//         {!provider ? "Connect Wallet" : "Disconnect Wallet"}
//       </button>
//     </>

//   );
// } 

function JobForm() {
  const { register, handleSubmit, watch, formState } = useForm();
  const formSuccess = () => toast.success('Form submitted!');
  const formFailure = () => toast.error('There was an error, blame ivan')
  const auth = firebase.auth
  const { currentUser } = useAuthValue()

  // const jobToDB = async (data) => {
  // try { 
  //   console.log("send it (to DB)");
  //   const documentReference = await firebase.db.collection('jobs').add(data)
  //   console.log('document reference ID', documentReference.id)
  //   return documentReference.id
  // }
  // catch (error) {
  //   console.log('error is in DB send')
  //         var errorCode = error.code
  //         var errorMessage = error.message
  //         console.log(errorCode)
  //         console.log(errorMessage)
  //         formFailure()
  //  }
  // }

  const sendJob = async (data) => {
    try {
    if (currentUser && currentUser.email)
    {const docRef = await firebase.db.collection('jobs').add(data)
    console.log(docRef);
    await formSuccess()
  }
    else {
      await signInAnonymously(auth)
      const docRef = await firebase.db.collection('jobs').add(data)
      console.log(docRef)
      await signOut(auth)
      await formSuccess()
    }}
    catch (error) {
      console.log('error is in sendJob')
      var errorCode = error.code
      var errorMessage = error.message
      console.log(errorCode)
      console.log(errorMessage)
      formFailure()
    }}  


  // const sendJob = (data) => {
  //     (currentUser && currentUser.email)
  //     ? jobToDB(data)
  //     : signInAnonymously(auth)
  //       .then(async () => {
  //         console.log("signed in anonymously"); 
  //         const docid = await jobToDB(data)
  //         docid.then(signOut(auth))
  //         console.log(docid);
  //         // jobToDB(data).then((docid) => {
  //         //   console.log(docid);
  //         //   signOut(auth)
  //         // })
  //       }).catch((error) => {
  //         signOut(auth)
  //         console.log('error is in sendjob')
  //         var errorCode = error.code;
  //         var errorMessage = error.message;
  //         console.log(errorCode);
  //         console.log(errorMessage);
  //         formFailure()
  //       })
  // }

  const onSubmit = async (data, e) => {
    data.date = Date.now()
    data.approved = false
    console.log(data);
    await sendJob(data)
    e.target.reset()
  }

  // console.log(watch("role")); // watch input value by passing the name of it
  return (
    <>
      <JobsHeader />
      <div className="col-span-6 md:col-span-6 text-left p-6 m-4 rounded-md space-y-4 border-2 border-red-400 shadow-red-500 shadow-neon">
        {/* <WalletButton provider={signer} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} /> */}
        <form onSubmit={handleSubmit(onSubmit)} className="bg-gray-900 rounded-md px-8 pt-6 pb-8">
          Project: <input placeholder="Lobster DAO" {...register("project", { required: true })} />
          {formState.errors.project && <p className="text-red-500">This field is required</p>}
          Twitter / Website: <textarea className="h-16" placeholder="Twitter: https://twitter.com/10b57e6da0&#10;Website: https://lobsterdao.io" {...register("website", { required: true })} />
          {formState.errors.website && <p className="text-red-500">This field is required</p>}
          Project Description: <textarea className="h-36" placeholder="Lobster DAO is a DAO for lobsters" {...register("blurb", { required: true, maxLength: 1500 })} />
          {formState.errors.blurb?.type === 'required' && <p className="text-red-500">This field is required</p>}
          {formState.errors.blurb?.type === 'maxLength' && <p className="text-red-500">Max 1500 characters plz, too much blahblah</p>}
          Looking For: <textarea className="h-24" placeholder="Put multiple roles if you want" {...register("role", { required: true, maxLength: 1500 })} />
          {formState.errors.role?.type === 'required' && <p className="text-red-500">This field is required</p>}
          {formState.errors.role?.type === 'maxLength' && <p className="text-red-500">Max 1500 characters plz, too much blahblah</p>}
          Salary: <input placeholder="1000 lobs nfts" {...register("salary", { required: true })} />
          {formState.errors.salary && <p className="text-red-500">This field is required</p>}
          Contact Info: <input placeholder="xyz@lobster.com or X on TG/twitter/discord" {...register("contact", { required: true })} />
          {formState.errors.contact && <p className="text-red-500">This field is required</p>}
          <input className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded disabled:opacity-25" type="submit" />
          {/* <input className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded disabled:opacity-25" type="submit" disabled={isSubmitting || !hasSignedWithLobs} /> */}

          <Toaster />
        </form>
        <JobsFooter />

      </div>
    </>
  )
}

export default JobForm